export const ErrorCodeMessage = {
    1000: 'Store parser not implemented',
    1001: 'Invalid URL format',
    1002: 'Invalid status',
    1003: 'Invalid vote type',
    1004: 'Vote already exists',
    1005: 'Deal not found',
    1006: 'Authentication failed',
    1007: 'Error decoding token',
    1008: 'No email or name found in token',
    1009: 'Error fetching user',
    1010: 'Error getting deals',
    1011: 'Error creating deal',
    1012: 'Store not supported',
    1013: 'Error creating user',
    1014: 'Error creating category',
    1015: 'Sorry, deal already exists, please try to add another deal, or report existing deal if it is expired',
    500: 'Internal server error',
};