import React, { useState, useEffect } from 'react';
import makeRequest from '../hooks/makeRequest';
import { useLocation } from 'react-router-dom';

const SideBar = () => {
    const [sections, setSections] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const fetchSections = async () => {
            try {
                const response = await makeRequest(`/api/all-sections-entries`, null, 'GET', false, true);
                console.log('craft cms response:', response.data);
                console.log('sections:', response.data[0]);

                setSections(response.data[0]);
            }
            catch (error) {
                console.log('Error fetching sections:', error);
                if(location.href === 'http://localhost:3000/'){
                    console.log('location.href:', location.href);
                    const test = [
                        {
                            "sectionName": "Buying Guide",
                            "entries": [
                                {
                                    "title": "Score Amazing Deals in 2024: Your Ultimate Shopping Calendar",
                                    "url": "https://www.peekdeals.com/posts/buying-guide/score-amazing-deals-in-2024-your-ultimate-shopping-calendar-2"
                                }
                            ]
                        },
                        {
                            "sectionName": "Top Deals This Day ",
                            "entries": []
                        }
                    ]
                    setSections(test);
                }else{
                    setSections([]);
                }
            }
        };
        fetchSections()

    }, []);

    return (
        <aside className="sidebar">
            <div className="sidebar-header">
                {sections.map((section) => (
                    <div key={section.sectionName}>
                        <h3
                            className={`section-name ${section.isActive ? 'active' : ''}`}
                        >
                            {section.sectionName}
                        </h3>
                        <ul>
                            {section.entries.map((entry) => (
                                <li key={entry.url}>
                                    <a href={entry.url} className={section.isActive ? 'active' : ''}>
                                        {entry.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </aside>
    );

}

export default SideBar;
