import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../images/logo.png';
import './Header.css'; // Import your Header.css file
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation

const SearchBar = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onSearch(searchTerm);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, onSearch, isFocused]);

    return (
        <div className="search-bar">
            <input
                type="text"
                placeholder="🔍  title, store, or price"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
        </div>
    );
};

const Header = ({ onSearch, onAddDealClick, onFAQClick, onLoginRedirect, onLogout, username }) => {
    const [theme, setTheme] = useState('default'); // 'default', 'dark-mode', 'colorful-light-mode'
    const isActive = (pathname) => location.pathname === pathname;

    const [showSubMenu, setShowSubMenu] = useState(false);

    useEffect(() => {
        document.body.className = ''; // Reset all classes
        document.body.classList.add(theme);
    }, [theme]);

    const themeOrbs = [
        { id: 'default', color: '#FFFFFF' }, // White for default theme
        { id: 'dark-mode', color: '#343a40' }, // Dark grey for dark mode
        { id: 'colorful-light-mode', color: '#e85a4f' }, // Warm red for colorful light mode
        { id: 'designed', color: '#2D3142' }
    ];
    const navigate = useNavigate();

    const navigateToDashboard = () => {
        navigate('/user/dashboard');
    };

    const navigateToHome = () => {
        navigate('/');
    }

    const location = useLocation();

    const isOnHomeTab = ((location.pathname === '/') || (location.pathname === '/login'))
    const isOnAddDeal = (location.pathname === '/add-deal')
    const isOnFAQ = (location.pathname === '/faq')

    return (
        <header className="header">
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="Let's Serve Together" />
                </Link>
            </div>

            <div className="breadcrumb">
                <nav >
                    <ul>
                        <li><Link to="/" className={isOnHomeTab ? 'active' : ''}>Home</Link></li>
                        <li><Link to="/add-deal" className={isOnAddDeal ? 'active' : ''}>Add a Deal</Link></li>
                        <li><Link to="/faq" className={isOnFAQ ? 'active' : ''}>FAQ</Link></li>
                        <li>{username ?
                            <div onMouseEnter={() => setShowSubMenu(true)} onMouseLeave={() => setShowSubMenu(false)}>
                                <div className={isActive('/user/dashboard') ? 'user-welcome-active' : 'user-welcome-text'}>Welcome, {username}</div>
                                {showSubMenu &&
                                    <ul className="submenu">
                                        <li><Link to="/user/dashboard">Dashboard</Link></li><
                                            li><Link onClick={onLogout}>Logout</Link></li>
                                    </ul>
                                }                </div>

                            : <Link onClick={onLoginRedirect}>Login</Link>

                        }
                        </li>
                    </ul>
                </nav>
            </div>
            {isOnHomeTab ? <SearchBar onSearch={onSearch} /> : null}
            <div className='theme-orbs-container'>
                {themeOrbs.map((orb) => (
                    <Link
                        key={orb.id}
                        onClick={() => setTheme(orb.id)}
                        className='orb-style'
                        style={{
                            backgroundColor: orb.color,
                        }}
                        aria-label={`Switch to ${orb.id} theme`}
                    />
                ))}
            </div>
        </header>

    )
};

export default Header;
