import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom'
import './App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDeals } from '../../hooks/useBackendUpdate';
import AddDeal from '../AddDeal/AddDeal';
import FAQPage from '../FAQ/FAQ';
import makeRequest from '../../hooks/makeRequest';
import Header from '../Header/Header';
import MainContent from '../MainContent';
import Footer from '../Footer';
import UserDashboard from '../UserDashboard/UserDashboard';
import ProtectedRoute from '../ProtectedRoute';
import utils from '../../utils';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-NWRVDR75VG');

const App = () => {
  const [deals, setDeals] = useState([]);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [usernameSelectionErrorMsg, setUsernameSelectionErrorMessage] = useState('');

  const [user, setUser] = useState({ name: '', choosingUsername: false, proposedName: '' });

  const location = useLocation();

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredDeals(deals); // Reset to all deals when search term is cleared
    } else {
      const filtered = filterDeals(deals, searchTerm);
      setFilteredDeals(filtered);
    }
  }, [searchTerm, deals]);

  useEffect(() => {
    if (location.pathname === '/login' || location.pathname === '/') {
      const fetchDeals = async () => {
        console.log('Fetching deals...')
        try {
          const fetchedDeals = await getDeals();
          setDeals(fetchedDeals);
          // sort fetched deals by upvote_count - downvote_count and then by date
          const sortedDeals = fetchedDeals.sort((a, b) => {
            const aScore = a.upvote_count - a.downvote_count;
            const bScore = b.upvote_count - b.downvote_count;
            if (aScore > bScore) return -1;
            if (aScore < bScore) return 1;
            return new Date(b.created_at) - new Date(a.created_at);
          });
          setFilteredDeals(sortedDeals);
        } catch (error) {
          console.error('Error fetching deals:', error);
          // Handle error appropriately
          setFilteredDeals([]);
        }
      };

      fetchDeals();
    }
    handleUserState(location);
  }, [location]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]);


  const handleUserState = (location) => {
    if (location.pathname === '/login') { // redirected by the backend after successful login
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      const name = queryParams.get('name');
      const username = queryParams.get('username');

      if (token) {
        localStorage.setItem('authToken', token);
        if (username) {
          setUser({ name: username });
        } else if (name) {
          setUser({ proposedName: name, choosingUsername: true });
        }
      } else {
        const storedToken = localStorage.getItem('authToken');
        const storedUsername = localStorage.getItem('username');
        if (storedToken && storedUsername) {
          setUser({ name: storedUsername });
        }
      }
    } else {
      const storedToken = localStorage.getItem('authToken');
      const storedUsername = localStorage.getItem('username');
      if (storedToken && storedUsername) {
        setUser({ name: storedUsername });
      }
    }
  };

  const handleVoteUpdate = (dealId, voteType, isSuccess) => {
    if (isSuccess) {
      console.log('setting filtered deals for vote update')
      setFilteredDeals(currentDeals => currentDeals.map(deal => {
        if (deal._id === dealId) {
          return {
            ...deal,
            upvote_count: voteType === 'up' ? deal.upvote_count + 1 : deal.upvote_count,
            downvote_count: voteType === 'down' ? deal.downvote_count + 1 : deal.downvote_count
          };
        }
        return deal;
      }));
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const filterDeals = (deals, term) => {
    const lowerTerm = term?.toLowerCase();
    return deals.filter(deal =>
      deal.title?.toLowerCase().includes(lowerTerm) ||
      deal.store_name?.toLowerCase().includes(lowerTerm) ||
      deal.sale_price?.toLowerCase().includes(lowerTerm)
    );
  };

  const handleUsernameSelection = async (chosenUsername) => {
    console.log('Submitting username:', chosenUsername);
    setUsernameSelectionErrorMessage(''); // Reset error message
    try {
      const validationResponse = await makeRequest('/validate/username', { username: chosenUsername }, 'POST', true);

      if (validationResponse.isAvailable) {
        console.log('Username available:', validationResponse);
        try {
          await makeRequest('/user/username/', { username: chosenUsername }, 'POST', true);
          setUser({ ...user, name: chosenUsername, choosingUsername: false });
          localStorage.setItem('username', chosenUsername);
          window.location.href = '/';
        } catch (error) {
          console.error('Error setting username:', error);
          setUsernameSelectionErrorMessage('Failed to set username. Please try again.');
        }
      } else {
        console.log('Username not available:', validationResponse);
        setUsernameSelectionErrorMessage('Username is already taken. Please choose another one.');
      }
    } catch (error) {
      console.error('Error validating username:', error);
      setUsernameSelectionErrorMessage('Error validating username. Please try again.');
    }
  };

  const handleAddDealClick = () => {
    navigate('/add-deal');
  };
  const handleFAQ = () => {
    navigate('/faq');
  };

  console.log("App render", [deals.length, filteredDeals.length]);

  return (
    <div className="app-container">
      <Header
        onSearch={handleSearch}
        onAddDealClick={handleAddDealClick}
        onFAQClick={handleFAQ}
        onLoginRedirect={handleLoginRedirect}
        onLogout={handleLogout}
        username={user.name}
      />

      <Routes>

        <Route path="/user/dashboard" element={
          <ProtectedRoute>
            <UserDashboard />
          </ProtectedRoute>
        } />
        <Route path="/add-deal" element={
          <ProtectedRoute>
            <AddDeal />
          </ProtectedRoute>
        } />
         <Route path="/edit-deal/:dealId" element={ 
         <ProtectedRoute>
            <AddDeal />
          </ProtectedRoute>} />
        <Route path="/faq" element={<FAQPage />} />

        {/* Define other routes here */}
        <Route path="/" element={
          <MainContent filteredDeals={filteredDeals} handleVote={handleVote} handleVoteUpdate={handleVoteUpdate} setFilteredDeals={setFilteredDeals} deals={deals} user={user} handleUsernameSelection={handleUsernameSelection} usernameSelectionErrorMsg={usernameSelectionErrorMsg} setUser={setUser} />} />
        <Route path="/login" element={
          <MainContent filteredDeals={filteredDeals} handleVote={handleVote} handleVoteUpdate={handleVoteUpdate} setFilteredDeals={setFilteredDeals} deals={deals} user={user} handleUsernameSelection={handleUsernameSelection} usernameSelectionErrorMsg={usernameSelectionErrorMsg} setUser={setUser} />} />
      </Routes>


      <Footer />

    </div>
  );
};

const logout = () => {
  // Remove the token from local storage or wherever it's stored
  localStorage.removeItem('authToken');

  // Optionally, redirect the user to the login page or home page
  window.location.href = '/'; // or wherever you wish to redirect
};

const handleLogout = async () => {
  try {
    // Send a request to the server to handle server-side logout
    await makeRequest('/logout', null, 'POST', true);

    // Clear the token from the client-side
    logout();
  } catch (error) {
    logout();
    console.error('Logout failed:', error);
  }
};

const handleVote = async (dealId, voteType) => {
  try {
    return await makeRequest(`/deal/${dealId}/vote`, { voteType }, 'POST', true);
  } catch (error) {
    console.error('Error voting on deal:', error);
    return Promise.reject(error);
  }
};

const handleLoginRedirect = () => {
  window.location.href = `${utils.getBaseBackendURL()}/connect/google`;
}

export default App;
