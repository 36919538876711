import React, { useState, useEffect } from 'react';
import './UserDashboard.css'
import Tab from './Tab';
import PendingDeals from './PendingDeals';
import makeRequest from '../../hooks/makeRequest';
import ActiveDeals from './ActiveDeals';
import { Link } from 'react-router-dom';

const UserDashboard = () => {
    const [userDeals, setUserDeals] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [pendingDeals, setPendingDeals] = useState([]);
    const [activeDeals, setActiveDeals] = useState([]);
    const [lastDeleted, setLastDeleted] = useState(null);
    const [errors, setErrors] = useState({}); // State for storing errors

    useEffect(() => {
        const fetchPendingDeals = async () => {
            try {
                const { deals } = await makeRequest('/deals/latest/pending', null, 'GET', true);
                console.log('Pending deals response:', deals);
                if (deals && deals.length > 0) {
                    setPendingDeals(deals);
                }
            } catch (error) {
                console.error('Error fetching pending deals:', error);
                // Handle error appropriately
            }
        };

        const fetchUserDeals = async () => {
            const response = await makeRequest('/user/dashboard', null, 'GET', true);
            if (response) {
                console.log('User dashboard response:', response);
                setUserDeals(response?.deals || []);
                setUserInfo(response?.user || {});
            }
        };

        const fetchActiveDeals = async () => {
            try {
                const { deals } = await makeRequest('/deals/latest/', null, 'GET', true);
                console.log('Active deals response:', deals);
                if (deals && deals.length > 0) {
                    setActiveDeals(deals);
                }
            } catch (error) {
                console.error('Error fetching pending deals:', error);
                // Handle error appropriately
            }
        };
        fetchActiveDeals();
        fetchPendingDeals();
        fetchUserDeals();
    }, [lastDeleted]);

    const handleDealAction = async (dealId, action, reason) => {
        try {
            setErrors(prevErrors => ({ ...prevErrors, [dealId]: undefined }));
            await makeRequest(`/deal/action/${dealId}`, { action, reason, dealId }, 'POST', true);
            setLastDeleted(Date.now()); // Update the state to trigger re-render
        } catch (err) {
            // Update error state for the specific project
            setErrors(prevErrors => ({ ...prevErrors, [dealId]: err.message || 'Error occurred' }));
        }
    };


    console.log('UserDashboard: user:', userInfo);
    console.log('UserDashboard: deals:', userDeals);
    console.log(userInfo.username)

    return (
        <div className="dashboard-container">
            <div className="dashboard-header">User Dashboard</div>
            <div className="dashboard-user-info">
                <p> <span>{userInfo.username}</span></p>
                <p> <span>{userInfo.email}</span></p>
                <p> Type <span>{userInfo.user_type}</span></p>
            </div>

            <Tab>
                {/* Existing deals table */}
                <div label="Deals by You">

                    <div className='dashboard-header'>Deals by you</div>
                    <table className="dashboard-table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Store Name</th>
                                <th>Price</th>
                                <th>Click Count</th>
                                <th>Image</th>
                                <th>Status</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userDeals.map((deal, index) => (
                                <tr key={index}>
                                    {/* on click, go to deal.url */}

                                    <td><a href={deal.url} target="_blank" rel="noopener noreferrer">{deal.title}</a></td>
                                    <td>{deal.store_name}</td>
                                    <td>${deal.sale_price}</td>
                                    <td>{deal.click_count}</td>
                                    <td><img src={deal.image_url} alt={deal.title} /></td>
                                    <td>{deal.status}</td>
                                    <td>{deal.status !== 'REJECTED' &&<Link to={`/edit-deal/${deal._id}`}>Edit</Link> }</td>
                                    <td>{deal.status !== 'REJECTED' &&<Link onClick={() => handleDealAction(deal._id, 'rejected', 'delete')}>Delete</Link>}</td>
                                
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pending Deals - Visible to MODs and ADMINs */}
                {['MOD', 'ADMIN'].includes(userInfo.user_type?.toUpperCase()) && (
                    <div label="Pending Deals">
                        <PendingDeals pendingDeals={pendingDeals} />
                    </div>
                )}

                {/* Active Deals - Visible to MODs and ADMINs */}
                {['MOD', 'ADMIN'].includes(userInfo.user_type?.toUpperCase()) && (
                    <div label="Active Deals">
                        <ActiveDeals activeDeals={activeDeals} />
                    </div>
                )}
            </Tab>
        </div>
    );
};

export default UserDashboard;
