import React, { useState } from 'react';
import './Tab.css';

const Tab = ({ children }) => {
    // Initialize the activeTab state with the label of the first child
    const [activeTab, setActiveTab] = useState(children[0]?.props?.label);

    console.log('Tab: children:', children);
    return (
        <div className="tab">
            <div className="tab-buttons">
                {React.Children.map(children, (child) => (
                    child &&
                    <button
                        className={child?.props?.label === activeTab ? 'tab-button active' : 'tab-button'}
                        onClick={() => setActiveTab(child?.props?.label)}
                    >
                        {child?.props?.label}
                    </button>
                ))}
            </div>
            <div className="tab-tab-content">
                {React.Children.map(children, child => (
                    child?.props?.label === activeTab ? child : undefined
                ))}
            </div>
        </div>
    );
};

export default Tab;
