// Updated FAQPage.js

import React, { useState } from 'react';
import './FAQ.css';

const FAQPage = () => {


  const faqs = [
    {
      category: 'General Questions',
      questions: [
        {
          question: 'Do I need to create an account to view deals on PeekDeals?',
          answer: 'No, you can view all the deals on our homepage without logging in. However, to add deals and earn commissions, you must create an account and log in.'
        },
        {
          question: 'How can I earn commissions on PeekDeals?',
          answer: 'By logging in and submitting deals to our website. When other users purchase through the deals you\'ve added, you earn a commission.'
        },
        {
          question: 'Which retailers are currently supported on PeekDeals?',
          answer: 'Currently, we support deals from Amazon. We plan to add more retailers in the future, so stay tuned!'
        },
      ]
    },
    {
      category: 'Account and Submission',
      questions: [
        {
          question: 'How do I create an account on PeekDeals?',
          answer: 'To create an account on PeekDeals, simply click on the "Login" link on our homepage and choose the "Continue with Google" option. Follow the prompts to sign in with your Google account, and your PeekDeals account will be automatically created.'
        },
        {
          question: 'How do I add a deal to PeekDeals?',
          answer: 'Once logged in, you\'ll find an option to "Add a Deal" on your dashboard. Fill in the details of the deal, including the title, store name, price, and a link to the deal.'
        },
        {
          question: `How can I manage the deals I've added on PeekDeals?`,
          answer: `Once you've logged in, navigate to your dashboard where you can see all the deals you've added. You have the option to edit or delete each deal as per your requirement.`
        },
        {
          question: 'Do all deals appear on the website immediately after being added?',
          answer: 'No, each deal goes through a moderation process for approval. Once approved by a moderator, the deal will be displayed on the website.'
        }
      ]
    },
    {
      category: 'Filtering and Searching',
      questions: [
        {
          question: 'How can I find deals on specific items or from specific stores?',
          answer: 'Use the filter options on our homepage. You can filter deals based on the title, store name, or price to find exactly what you\'re looking for.'
        },
      ]
    },
    {
      category: 'Technical Support',
      questions: [
        {
          question: 'I\'m having trouble using the website. How can I get help?',
          answer: 'For any technical issues, please reach out to us at support@peekdeals.com. Our team will assist you as soon as possible.'
        },
      ]
    },
    {
      category: 'Future Updates',
      questions: [
        {
          question: 'Will PeekDeals be adding more features or retailers?',
          answer: 'Yes, we are always working to improve PeekDeals and plan to add more retailers and features. Keep an eye on our updates for new additions.'
        },
      ]
    },
  ];
  

  const [activeIndex, setActiveIndex] = useState('0-0');

  const toggleFAQ = index => {
    console.log('index:', index);
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      {faqs.map((faqCategory, categoryIndex) => (
        <div key={categoryIndex}>
          <h3>{faqCategory.category}</h3>
          <div className="faqs">
            {faqCategory.questions.map((faq, index) => (
              <div 
                className={`faq ${activeIndex === categoryIndex + '-' + index ? 'active' : ''}`} 
                key={categoryIndex + '-' + index} 
                onClick={() => toggleFAQ(categoryIndex + '-' + index)}
              >
                <div className="faq-question">
                  {faq.question}
                </div>
                <div className="faq-answer">
                  {faq.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQPage;
