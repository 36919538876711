import utils from '../utils';

const makeRequest = async (path, data, method, needsAuth, isCMS = false) => {
    let token = null;
    if (needsAuth) {
        token = localStorage.getItem('authToken');
        if (!token) {
            // Handle the case where there is no token - e.g., redirect to login
            throw new Error('Authentication failed');
        }
    }

    const host = isCMS ? utils.getCMSBackendURL() : utils.getBaseBackendURL();

    const headers = {
        'Content-Type': 'application/json'
    };

    if (needsAuth) {
        headers['X-Auth-Token'] = token;
    }
 // Conditionally prepare the body only for methods that can have a body
 const body = method !== 'GET' && method !== 'HEAD' ? JSON.stringify(data) : undefined;


    const options = {
        method,
        headers,
    };
    if (body) {
        options.body = body;
      }
    return await fetch(`${host}${path}`, options)
    .then(async (res) => {
        if (!res.ok) {
            const errorResponse = await res.json().catch(() => {
                // Handle case where the error response is not JSON or cannot be parsed
                throw { status: res.status, body: { errorMessage: res.statusText } };
            });
            // Assuming errorResponse is already in the desired format
            throw { status: res.status, body: errorResponse };
        }
        return res.json();
    })
        // .then(async (res) => {
        //     if (!res.ok) {
        //         // If the response is not okay, parse the error response and throw it
        //         try {
        //             const errorResponse = await res.json();
        //             throw { status: res.status, body: errorResponse };
        //         } catch (error) {
        //             throw { status: res.status, body: res.statusText };
        //         }
        //     }
        //     if (res.headers.get('content-type')?.includes('application/json')) {
        //         return res.json();
        //     } else {
        //         throw new Error('Non-JSON response received');
        //     }
        // })
        .then((data) => {
            // console.log('###########*******####### data: ', data);
            return data;
        })
        .catch((err) => {
            // Log and re-throw or handle the error appropriately here
            console.error('Error:', err);
            throw err;
        });
};

export default makeRequest;