import React, { useState } from 'react';

const MerchantFilter = ({ deals, onFilterUpdate }) => {
    const [selectedMerchant, setSelectedMerchant] = useState('All');

    // Calculate top merchants using 'store_name' from the new deal format
    const merchantCounts = deals.reduce((acc, deal) => {
        if (!deal.store_name) return acc;
        acc[deal.store_name] = (acc[deal.store_name] || 0) + 1;
        return acc;
    }, {});

    const topMerchants = Object.entries(merchantCounts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5)
        .map(entry => entry[0]);

    // Handle merchant selection using 'store_name'
    const handleMerchantClick = (merchant) => {
        console.log('Selected merchant:', merchant);
        setSelectedMerchant(merchant);


        onFilterUpdate(() => {
            const filteredDeals = deals.filter(deal => merchant === 'All' || (deal.store_name && deal.store_name === merchant));
            console.log('Merchant filter:Filtered deals:', filteredDeals);
            return filteredDeals;
        });
    };

    return (
        <div className="merchant-filter">
            {/* Add 'All' pill at the beginning */}
            <button
                onClick={() => handleMerchantClick('All')}
                className={selectedMerchant === 'All' ? 'selected' : ''}
            >
                All
            </button>
            {/* Existing code to display top merchants */}
            {topMerchants.map(merchant => (
                merchant &&
                <button
                    key={merchant}
                    onClick={() => handleMerchantClick(merchant)}
                    className={selectedMerchant === merchant ? 'selected' : ''}
                >
                    {merchant}
                </button>
            ))}
        </div>
    );
};

export default MerchantFilter;