import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import MerchantFilter from './MerchantFilter';
import React, { useState } from 'react';
import { event } from 'react-ga4';
import ShareButton from './ShareButton';
import SideBar from './SideBar';


const MainContent = ({ filteredDeals, handleVote, handleVoteUpdate, setFilteredDeals, deals, user, handleUsernameSelection, usernameSelectionErrorMsg, setUser }) => {
    return (
        <>
            <MerchantFilter deals={deals} onFilterUpdate={setFilteredDeals} />
            <div className='main-content-container'>
                <div className="main-content">
                    {user.choosingUsername && (
                        <UsernameChoice
                            proposedUsername={user.proposedName}
                            onUsernameSubmit={handleUsernameSelection}
                            onCancel={() => setUser({ ...user, choosingUsername: false })}
                            errorMsg={usernameSelectionErrorMsg}
                        />
                    )}
                    {filteredDeals.map(deal => (
                        <DealCard key={deal._id} deal={deal} handleVote={handleVote} handleVoteUpdate={handleVoteUpdate} />
                    ))}
                </div>
                <SideBar />
            </div>
        </>
    );
};

const UsernameChoice = ({ proposedUsername, onUsernameSubmit, errorMsg, onCancel }) => {
    return (
        <div className="username-choice">
            <p>Would you like to use "{proposedUsername}" as your username?</p>
            <button onClick={() => onUsernameSubmit(proposedUsername)}>Yes</button>
            <p>Or choose a different username</p>

            <form onSubmit={(e) => {
                e.preventDefault();
                onUsernameSubmit(e.target.username.value);
            }}>
                <input name="username" type="text" placeholder="Enter new username" />
                {errorMsg && <div className="error-message">{errorMsg}</div>}
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

// Update DealCard to use the new deal properties directly
const DealCard = ({ deal, handleVote, handleVoteUpdate }) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrevImage = () => {
        setCurrentImageIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : deal.image_url.length - 1);
    };

    const handleNextImage = () => {
        setCurrentImageIndex(prevIndex => prevIndex < deal.image_url.length - 1 ? prevIndex + 1 : 0);
    };

    const handleDealClick = (dealId, action) => {
        event({
            category: 'Deal',
            action: action,
            label: `Deal ID: ${dealId}`
        });
    };

    // let gradientColor = 'lightgray';

    // Updated parsePrice function to work with new price format
    // const parsePrice = (priceStr) => {
    //     return priceStr ? parseFloat(priceStr.replace(/,/g, '')) : null;
    // };

    // try {
    //     const priceNum = parsePrice(deal.sale_price["$numberDecimal"]);
    //     const OGPriceNum = parsePrice(deal.list_price["$numberDecimal"]);
    //     if (priceNum && OGPriceNum) {
    //         const discountPercentage = Math.round((1 - priceNum / OGPriceNum) * 100);
    //         gradientColor = `linear-gradient(90deg, green, green ${discountPercentage}%, amber ${discountPercentage}%, amber 100%)`;
    //     }
    // } catch (err) {
    //     console.log('Error parsing price: ', err);
    // }

    return (
        <div className="deal-card">
            {Array.isArray(deal.image_url) && deal.image_url.length > 1 ? (
                <>
                    <a href={deal.url} target="_blank" rel="noopener noreferrer" key={deal._id} onClick={() => handleDealClick(deal._id, 'Image Click')} className='deal-image-container'>
                        <div className="deal-card-image-slider" style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}>
                            {deal.image_url.map((url, index) => (
                                <img src={url} alt={deal.title} key={url} className="deal-slider-image" />
                            ))}
                        </div>
                    </a>

                    <button onClick={handlePrevImage} className="left-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z" /><path d="M13.293 7.293 8.586 12l4.707 4.707 1.414-1.414L11.414 12l3.293-3.293-1.414-1.414z" /></svg> </button>
                    <button onClick={handleNextImage} className="right-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z" /><path d="M9.293 8.707 12.586 12l-3.293 3.293 1.414 1.414L15.414 12l-4.707-4.707-1.414 1.414z" /></svg></button>
                </>
            ) : (
                <a href={deal.url} target="_blank" rel="noopener noreferrer" onClick={() => handleDealClick(deal._id, 'Image Click')} className='deal-image-container'>
                    <img src={deal.image_url} alt={deal.title} className="deal-image" />
                </a>
            )}

            <div className="deal-info">
                <a href={deal.url} target="_blank" rel="noopener noreferrer" onClick={() => handleDealClick(deal._id, 'Title Click')} className="deal-name-link">
                    <h2 className="deal-name">{deal.title}</h2>
                </a>
                <div className="price-container">
                    <a href={deal.url} target="_blank" rel="noopener noreferrer" onClick={() => handleDealClick(deal._id, 'Price Click')} className="deal-price-link">
                        <p className="deal-price">{deal.sale_price?'$'+deal.sale_price : ''}</p>
                    </a>
                    <a href={deal.url} target="_blank" rel="noopener noreferrer" onClick={() => handleDealClick(deal._id, 'Original Price Click')} className="original-price-link">
                        <p className="original-price">{deal.list_price?'$'+deal.list_price : ''}</p>
                    </a>
                </div>
                <p className="deal-merchant">{deal.store_name || ''}</p>

            </div>
            <div className="vote-share-section">
                <div className="vote-count upvote" onClick={() => handleVote(deal._id, 'up').then(() => handleVoteUpdate(deal._id, 'up', true))}>
                    <FaThumbsUp />
                    <span>{deal.upvote_count}</span>
                </div>
                <ShareButton shareUrl={deal.url} />
                <div className="vote-count downvote" onClick={() => handleVote(deal._id, 'down').then(() => handleVoteUpdate(deal._id, 'down', true))}>
                    <FaThumbsDown />
                    <span>{deal.downvote_count}</span>
                </div>
            </div>
        </div>
    );
};

export default MainContent;