import React, { useState } from 'react';
import makeRequest from '../../hooks/makeRequest';
import './Tab.css';

const ActiveDeals = ({ activeDeals }) => {
    const [errors, setErrors] = useState({}); // State for storing errors
    const [updatedDeals, setUpdatedDeals] = useState({}); // State to track updated deals

    const handleDealAction = async (dealId, action, reason) => {
        try {
            setErrors(prevErrors => ({ ...prevErrors, [dealId]: undefined }));

            await makeRequest(`/deal/action/${dealId}`, { action, reason, dealId }, 'POST', true);

            setUpdatedDeals(prevUpdated => ({ ...prevUpdated, [dealId]: true }));
        } catch (err) {
            // Update error state for the specific deal
            setErrors(prevErrors => ({ ...prevErrors, [dealId]: err.message || 'Error occurred' }));
        }
    };

    console.log('Rendering activeDeals: activeDeals:', activeDeals)
    return (
        <div className="active-deals-container">
            {activeDeals.map((deal, index) => (
                <div key={index} className={`active-deal ${updatedDeals[deal._id] ? 'deal-updated' : ''}`}>
                    <div className="tab-deal-info">
                        <div className="deal-title">
                            <strong>Title:</strong> {deal.title}
                        </div>
                        <div className="deal-store">
                            <strong>Store Name:</strong> {deal.store_name}
                        </div>
                        <div className="deal-price">
                            <strong>Price:</strong> ${deal.sale_price}
                        </div>
                        <div className="deal-image">
                            <a href={deal.url} target="_blank" rel="noopener noreferrer">
                                <img src={deal.image_url} alt={deal.title} style={{ width: '100px', height: 'auto', borderRadius: '5px' }} />
                            </a>
                        </div>
                    </div>

                    <div className="deal-actions">
                        {/* Action buttons here */}
                        <button className='tab-deal-actions-reject' onClick={() => handleDealAction(deal._id, 'rejected', 'expired')}>Reject as Expired</button>
                        <button className='tab-deal-actions-reject' onClick={() => handleDealAction(deal._id, 'rejected', 'invalid')}>Reject as Invalid</button>
                        <button className='tab-deal-actions-reject' onClick={() => handleDealAction(deal._id, 'rejected', 'spam')}>Reject as Spam</button>
                        <button className='tab-deal-actions-reject' onClick={() => handleDealAction(deal._id, 'rejected', 'inappropriate')}>Reject as Inappropriate</button>
                        {/* Add more actions as needed */}
                    </div>
                    {errors[deal._id] && <div className="tab-error-message">{errors[deal._id]}</div>}
                </div>
            ))}
        </div>
    );
};

export default ActiveDeals;
