import React, { useState, useEffect, useRef } from 'react';
import { Editor, EditorState, convertToRaw, ContentState,convertFromRaw, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './AddDeal.css';
import makeRequest from '../../hooks/makeRequest';
import { useNavigate } from 'react-router-dom';
import { ErrorCodeMessage } from '../../error_code';
import { event } from 'react-ga4';
import { useParams } from 'react-router-dom'; // Import the useParams hook

const AddDeal = () => {
    const { dealId } = useParams(); // Get dealId from URL

    const [dealData, setDealData] = useState({
        title: '',
        list_price: '',
        sale_price: '',
        merchant: '',
        category: '',
        brand: '',
        image_url: []
    });

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const [dealUrl, setDealUrl] = useState('');
    const [isLoadingDeal, setIsLoadingDeal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const errorRef = useRef(null);
    const navigate = useNavigate();

    const [currentImageUrl, setCurrentImageUrl] = useState(''); // State to hold the current input value

    const handleImageUrlChange = (e) => {
        setCurrentImageUrl(e.target.value);
    };


    useEffect(() => {
        const fetchDealData = async () => {
            if (dealId) {
                const data = await makeRequest(`/deals/${dealId}`, {}, 'GET');
                const dealData = data.deal;
                // setDealUrl(dealData.original_url);
                const rawContentFromDB = JSON.parse(dealData.description);
                const contentState = convertFromRaw(rawContentFromDB);
                const newEditorState = EditorState.createWithContent(contentState);

                setEditorState(newEditorState);
                setDealData({ ...dealData, sale_price: dealData.sale_price.$numberDecimal, list_price: dealData.list_price.$numberDecimal } || {}); // Assuming the API returns an object with a 'project' key
            }
        };

        if (dealId) {
            fetchDealData();
        } else {
            // Reset form when there's no projectId (new project scenario)
            setDealData({
                title: '',
                list_price: '',
                sale_price: '',
                merchant: '',
                category: '',
                brand: '',
                image_url: []
            });
        }
    }, [dealId]);

    const handleUploadClick = () => {
        if (currentImageUrl) {
            // Add the current image URL to the dealData.image_url array
            setDealData({ ...dealData, image_url: [...dealData.image_url, currentImageUrl] });
            setCurrentImageUrl(''); // Reset the input field after adding the URL
        }
    };
    useEffect(() => {
        let isMounted = true; // flag to check if the component is still mounted

        const handleUrlPaste = async (url) => {
            if (!isMounted) return; // exit if component is not mounted

            setIsLoadingDeal(true);
            try {
                const autopopulatedData = await autopopulateDealFields(url);
                if (autopopulatedData.description) {
                    const contentState = ContentState.createFromText(autopopulatedData.description);
                    setEditorState(EditorState.createWithContent(contentState));
                } else {
                    setEditorState(EditorState.createEmpty());
                }
                setDealData(prevData => ({ ...prevData, ...autopopulatedData }));
                setErrorMessage('');
                setIsLoadingDeal(false);
            } catch (err) {
                setIsLoadingDeal(false);
            }
        };

        const timer = setTimeout(() => {
            if (dealUrl) {
                handleUrlPaste(dealUrl);
            }
        }, 500);

        return () => {
            clearTimeout(timer);
            isMounted = false; // set flag to false when component unmounts
        };
    }, [dealUrl]);

    const autopopulateDealFields = async (url) => {
        // Mock function to be replaced with real data fetching
        // make a post request to localhost:5001/deals/info with the url in the body
        try {
            const { deal } = await makeRequest('/deal/info', { url }, 'POST', true);

            console.log('deal from backend: ', { ...deal });
            return { ...deal } || {
                title: 'Sample Product',
                list_price: '100.00',
                sale_price: '50.00',
                description: 'Sample description',
                store_name: 'Amazon',
                category: 'Electronics',
                brand: 'Sample Brand',
                image_url: 'https://m.media-amazon.com/images/W/MEDIAX_792452-T2/images/I/81srTmOO7TL._AC_SY695_.jpg'
            };
        } catch (err) {
            errorRef.current?.scrollIntoView({ behavior: 'smooth' });
            if (err.message === '400') {
                const message = 'Unsupported URL or store'
                console.log(message);
                setErrorMessage(message);
                throw err;
            } else if (err.message === '500') {
                const message = 'Error fetching deal info'
                console.log(message);
                setErrorMessage(message);
                throw err;
            }
            console.log('Error fetching deal info: ', err);
            return {};
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDealData({ ...dealData, [name]: value });
    };

    // const getUserId = () => {
    //     const userId1 = "2e3b92bd-d5a9-47c1-b4f8-7067e5458efb";
    //     const userId2 = "6b2eb565-28c2-442f-a582-615df13a49d3";
    //     // return either userId1 or userId2 randomly
    //     return Math.random() < 0.5 ? userId1 : userId2;
    // }
    const validateForm = async () => {
        if(dealId){
            setDealUrl( dealData.original_url);
        }
        if(!dealId && !dealUrl){
            errorRef.current?.scrollIntoView({ behavior: 'smooth' });
            setErrorMessage('Please enter a valid deal URL');
            return false;
        }else if ( !dealData.title || !dealData.list_price || !dealData.sale_price || !dealData.store_name || !editorState.getCurrentContent().hasText()) {
            errorRef.current?.scrollIntoView({ behavior: 'smooth' });
            console.log('inside validateForm error');
            setErrorMessage('Please fill all required fields');
            return false;
        } else {
            setErrorMessage(''); // Clear error message if the form is valid
            console.log('inside validateForm no error');
            return true;
        }
    };
    const addDeal = async (e) => {
        const rawDescription = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        console.log('rawDescription: ', rawDescription)
        const dealSubmissionData = { ...dealData, description: rawDescription };

        try {
            await makeRequest('/user/deal', { deal: { ...dealSubmissionData, ...{ store_name: dealData.store_name, brand: undefined, category: undefined } } }, 'POST', true).then(navigateToHome);
            handleAddDealClick(dealData.store_name, 'Add Deal', 'Success');
        } catch (err) {
            // Assuming err contains the error response directly
            const errorCode = err.body?.errorCode || 'DEFAULT_ERROR_CODE';
            const errorMessage = ErrorCodeMessage[errorCode] || err.body?.errorMessage || 'An error occurred';
            const statusCode = err.status;
            console.log('error code message is : ',errorMessage);
            console.log('store_name is : ',dealData.store_name);

            handleAddDealClick(dealData.store_name, 'Add Deal', `Failure:${errorMessage}`);
            console.error('Error Code:', errorCode);
            console.error('Error Message:', errorMessage);
            console.error('statusCode :', statusCode);

            errorRef.current?.scrollIntoView({ behavior: 'smooth' });
            setErrorMessage(errorMessage);
        }

    };

    const updateDeal = async (dealId) => {
        try {
            const rawDescription = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
            const dealSubmissionData = { ...dealData, description: rawDescription};
            await makeRequest(`/user/deal/${dealId}`, { deal: { ...dealSubmissionData, ...{ store_name: dealData.store_name, brand: undefined, category: undefined } } }, 'PUT', true)
                .then(
                   navigate('/user/dashboard')
                )
                .catch((err) => {
                    const errorMessage = err.message || 'An error occurred';
                    errorRef.current?.scrollIntoView({ behavior: 'smooth' });
                    setErrorMessage(errorMessage);
                });
            handleUpdateDealClick(dealData.store_name, 'Update Deal', 'Success')
        } catch (err) {
            const errorCode = err.body?.errorCode || '500';
            const errorMessage = ErrorCodeMessage[errorCode] || err.body?.errorMessage || 'An error occurred';
            const statusCode = err.status?.toString() || '500';

            console.error('Error Code:', errorCode);
            console.error('Error Message:', errorMessage);
            console.error('statusCode :', statusCode);
            handleUpdateDealClick(dealData.store_name, 'Update Deal',  `Failure:${errorMessage}`);

            errorRef.current?.scrollIntoView({ behavior: 'smooth' });
            setErrorMessage(errorMessage);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isFormValid = await validateForm();
        if (!isFormValid) {
            return;
        }
        if (dealId) {
            updateDeal(dealId);
        } else {
            addDeal();

        }
    };
    // Function to handle login redirection
    const handleLoginRedirect = () => {
        window.location.href = 'http://localhost:5001/connect/google';
    };

    // if no authToken in localStorage, redirect to login page
    if (!localStorage.getItem('authToken')) {
        handleLoginRedirect();
    }

    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const toggleInlineStyle = (inlineStyle) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const undoAction = () => {
        setEditorState(EditorState.undo(editorState));
    };

    const redoAction = () => {
        setEditorState(EditorState.redo(editorState));
    };

    const navigateToHome = () => {
        navigate('/');
    }



    const handleAddDealClick = (storeName, action, status) => {
        event({
            category: 'Add Deal',
            action: action,
            label: `Store: ${storeName}, Status: ${status}`
        });
    };

    const handleUpdateDealClick = (storeName, action, status) => {
        event({
            category: 'Update Deal',
            action: action,
            label: `Store: ${storeName}, Status: ${status}`
        });
    };
    return (
        <div className="add-deal-page-add-deal-container">

            {isLoadingDeal && (
                <div className="add-deal-page-progress-bar"></div>
            )}

            <form onSubmit={handleSubmit} className="add-deal-page-add-deal-form">
                <div ref={errorRef}>
                    {errorMessage && <p className="add-deal-page-error-message">{errorMessage}</p>}
                </div>

                <div className="add-deal-page-inputs-container">
                    <div className="add-deal-page-input-group">
                        <label htmlFor="dealUrl">Deal URL</label>
                        {/* If dealId exists, the deal URL is read-only */}
                        <input
                            type="text"
                            id="dealUrl"
                            name="dealUrl"
                            placeholder="Paste deal URL here"
                            value={dealUrl}
                            onChange={(e) => setDealUrl(e.target.value)}
                            required
                            readOnly={dealId ? true : false} // Explicitly set readOnly based on dealId's existence
                            />
        
                    </div>
                    <div className="add-deal-page-input-group">
                        <label htmlFor="title">Title</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            placeholder="Title"
                            value={dealData.title}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="add-deal-page-price-input-group">
                        <label htmlFor="list_price">List Price</label>
                        <input
                            type="text"
                            id="listPrice"
                            name="list_price"
                            placeholder="List Price"
                            value={dealData.list_price}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="salePrice">Sale Price</label>
                        <input
                            type="text"
                            id="salePrice"
                            name="sale_price"
                            placeholder="Sale Price"
                            value={dealData.sale_price}
                            onChange={handleChange}
                            required
                        />

                    </div>
                    {/* add merchant */}
                    <div className="add-deal-page-input-group">
                        <label htmlFor="merchant">Merchant</label>
                        <input
                            type="text"
                            id="merchant"
                            name="store_name"
                            placeholder="Merchant Name"
                            value={dealData.store_name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="add-deal-page-input-group add-deal-page-editor">
                        <label htmlFor="description">Description</label>
                        <div className="add-deal-page-editor-controls">
                            <button type="button" onClick={() => toggleInlineStyle('BOLD')}>𝐁</button>
                            <button type="button" onClick={() => toggleInlineStyle('ITALIC')}>𝐼</button>
                            <button type="button" onClick={() => toggleInlineStyle('UNDERLINE')}> 𝑈</button>
                            <button type="button" onClick={() => toggleInlineStyle('STRIKETHROUGH')}>S̶</button>
                            <button type="button" onClick={() => toggleBlockType('unordered-list-item')}> - </button>
                            <button type="button" onClick={() => toggleBlockType('ordered-list-item')}> 1. </button>
                            <button type="button" onClick={() => toggleBlockType('unstyled')}>P</button>
                            <button type="button" onClick={() => toggleBlockType('header-three')}>H3</button>
                            <button type="button" onClick={() => toggleBlockType('header-two')}>H2</button>
                            <button type="button" onClick={() => toggleBlockType('header-one')}>H1</button>
                            <button type="button" onClick={undoAction}>↺</button>
                            <button type="button" onClick={redoAction}>↻</button>
                        </div>
                        <div className="add-deal-page-editor-container">

                            <Editor
                                editorState={editorState}
                                onChange={setEditorState}
                                handleKeyCommand={handleKeyCommand}
                            />
                        </div>
                    </div>
                </div>
                <div className="deal-page-container">
                    {/* Display uploaded images */}
                    {dealData.image_url.map((url, index) => (
                        <img key={index} className='add-deal-page-deal-image' src={url} alt={`Deal ${index + 1}`} />
                    ))}

                    {/* Image URL input and upload button */}
                    <div className="add-deal-page-input-group">
                        <label htmlFor="imageUrl">Image URL</label>
                        <input
                            type="text"
                            id="imageUrl"
                            name="image_url"
                            placeholder="Image URL"
                            value={currentImageUrl}
                            onChange={handleImageUrlChange}
                        />
                        <button type="button" onClick={handleUploadClick}>Upload Image</button>
                    </div>
                </div>

                <button type="submit" onClick={handleSubmit} className="add-deal-page-add-deal-button">{dealId ? 'Update Deal' : 'Add Deal'}</button>
            </form>
        </div>
    );
};

export default AddDeal;
