
const getBaseBackendURL = () => {
    return window.location.hostname === "localhost" ? "http://localhost:5001" : "https://api.peekdeals.com";
};

const getBaseWindowURL = () => {
    return window.location.hostname === "localhost" ? "http://localhost:3000" : "https://www.peekdeals.com";
}

const getCMSBackendURL = () => {
    return window.location.hostname === ("localhost" || "http://craft.ddev.site") ? "http://craft.ddev.site" : "https://peekdeals.com/posts";
};

console.log('getBaseBackendURL: ', getBaseBackendURL());
console.log('getBaseWindowURL: ', getBaseWindowURL());
console.log('getCMSBackendURL: ', getCMSBackendURL());

export default {
    getBaseBackendURL,
    getBaseWindowURL,
    getCMSBackendURL
};